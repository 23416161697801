<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="page-item door" v-for="item in doorList" :key="item.id" @click="chooseDoor(item)">
        <div class="page-icon SGCC"></div>
        <div class="info">
          <div class="f38">
            <span>{{item.acctFlag | acctFlagFilter}} / {{item.city}} /{{item.idcard}}</span>
          </div>
          <div class="f40 margin-tb2"><strong>{{item.acctNo}}</strong></div>
          <span v-if="item.isDefault === 'Y'" class="default mainBut-plain f34">默认</span>

        </div>
        <div class="but">
          <van-icon name="edit" @click.stop="eidtDoor(item)" />
          <van-icon name="delete-o" @click.stop="delDoor(item)" />
        </div>
      </div>

      <div class="submit-box">

        <div class="submitBut" @click="goPage('/bindDoorNo')">添加户号</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'
import { Dialog } from 'vant';


const acctFlagMap = Object.freeze([
  { name: '国家电网', value: 'SGCC' },
  { name: '南方电网', value: 'CSG' }]
)

export default {
  name: 'doorList',
  data() {
    return {
    }
  },
  computed: {
    ...mapState('electricity', ['doorList', 'themesMap']),
  },
  filters: {
    acctFlagFilter(val) {
      let item = acctFlagMap.find(v => v.value === val)
      return item ? item.name : val
    }
  },
  created() {
    this.getDoorList()
  },
  methods: {
    ...mapActions('electricity', ['getDoorList']),

    // 选择户号
    chooseDoor(item) {
      this.$store.commit('electricity/SET_DEFAULTDOOR', item)
      this.$router.go(-1)
    },

    // 编辑户号
    eidtDoor(item) {
      this.$store.commit('electricity/SET_EDITDOORDATA', item)
      this.goPage('/editDoorNo')
    },

    // 删除户号
    delDoor(item) {
      const bodyClass = document.body.className
      let theme = this.themesMap.find(v => v.value === bodyClass)
      Dialog.confirm({
        title: '提示',
        message: '您确认删除该户号?',
        confirmButtonColor: theme.color,
        width: '80vw'
      })
        .then(() => {
          let params = {
            trxCode: 'XF334',
            id: item.id
          }
          mallApi(params).then(res => {
            const { rspCode, rspMsg, data } = res
            if (rspCode === '0000') {
              this.getDoorList()
              this.$toast('删除成功')
            } else {
              this.$toast(rspMsg)
            }
          })
        })
        .catch(() => {
          // on cancel
        });

    },
    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },
  }
}
</script>

<style lang='less' scoped>
.door {
  display: flex;
  align-items: center;
  .page-icon {
    width: 54px;
    height: 54px;
  }
  .page-icon.SGCC {
    background-position: -451px -142px;
  }

  .info {
    flex: 1;
    margin: 0 12px;

    .default {
      padding: 0 1vw;
      margin-right: 4px;
      border-radius: 2px;
    }
  }
  .but .van-icon {
    margin-left: 12px;
    font-size: 6vw;
  }
}
.submit-box {
  position: fixed;
  width: 100%;
  bottom: 0;
}
</style>